@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: rgb(0,0,0);
    
    color: white;
}

.custom-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 200px;
}

.custom-shape .shape-fill {
    fill: #FFFFFF;
}

body::-webkit-scrollbar {
    display: none;
  }
  
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .page__bg::before {
      background: url('./assets/dot-c.png') repeat-x;
      height: 100%;
      background-position: center;
      background-attachment: fixed;
      background-size: contain;
      opacity: 0.05;
  }
  